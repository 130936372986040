<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Offline Employees</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Offline Employees</h2>
        </div>
        <div class="col-md-1 text-right"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="employeesData"
                :fields="fields"
                show-empty
                sticky-header
              >
                <template v-slot:cell(id)="data">
                  {{ data.item.idno }}
                </template>
                <template v-slot:cell(employee)="data">
                  {{ data.item.lastname }}, {{ data.item.firstname }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      profileData: null,
      currentPage: 1,
      perPage: 2,
      fields: [
        {
          key: "id",
          label: "Employee Id",
        },
        {
          key: "employee",
        },
        {
          key: "company",
        },
        {
          key: "department",
        },
        {
          key: "jobposition",
          label: "Position",
        },
        {
          key: "employmentstatus",
          label: "Status",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.state.employees.length;
    },
    employeesData() {
      return this.$store.state.OfflineEmployees;
    },
  },
  mounted() {
    this.$store.dispatch("loadOfflineEmployees");
  },
  methods: {
    /* showEmployee(index){
            this.profileData = this.$store.state.employees[index];
        },
        deleteProfile: async function(id){
          if(confirm("Are you sure you want to delete this? Deleting this account also deletes the following data: Employee's Attendance, Schedules, Leaves, User Account, or All records associated with this Employee.")){
            await this.$store.dispatch('deleteProfile',id);
            this.flashMessage.success({
              message: 'Employee deleted successfully',
              time: 3000
            });
            this.$store.dispatch('loadEmployees');
          }
        },
        archiveProfile: async function(id){
          if(confirm("Are you sure you want to archive this employee ?")){
            this.$store.dispatch('archiveProfile',id);
            this.flashMessage.success({
              message: 'Employee archived successfully',
              time: 3000
            });
            this.$store.dispatch('loadEmployees');
          }
        } */
  },
};
</script>
